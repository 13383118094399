<template>
  <div>
    <b-table-simple bordered small responsive>
      <b-thead>
        <b-tr>
          <b-th class="th-class-2" colspan="10"
            >INFORME DE PROGRESIÓN DE
            {{
              $getVisibleNames(
                "mesh.competence",
                true,
                "Competencias"
              ).toUpperCase()
            }}
            <span v-if="career">
              {{ " DE " + career.name.toUpperCase() }}
            </span>
          </b-th>
        </b-tr>
        <b-tr>
          <b-th class="th-class" colspan="3">{{
            $getVisibleNames(
              "mesh.competence",
              true,
              "Competencias"
            ).toUpperCase()
          }}</b-th>
          <b-th
            class="th-class-2"
            :colspan="school && school.show_ra_macro ? 5 : 4"
            >{{
              $getVisibleNames(
                "mesh.egressprofilematter",
                true,
                "Asignaturas"
              ).toUpperCase()
            }}
            QUE TRIBUTAN</b-th
          >
          <b-th class="th-class-3" colspan="2">LOGROS</b-th>
        </b-tr>
        <b-tr> </b-tr>
        <b-th class="th-class" :rowspan="2">NÚMERO</b-th>
        <b-th class="th-class" :rowspan="2">ENUNCIADO</b-th>
        <b-th class="th-class" :rowspan="2">TIPO</b-th>
        <b-th class="th-class" :rowspan="2">NOMBRE</b-th>
        <b-th class="th-class" :rowspan="2">NIVEL</b-th>
        <b-th
          class="th-class"
          :rowspan="2"
          v-if="school && school.show_ra_macro"
          >{{
            $getVisibleNames(
              "teaching.ramacro",
              false,
              "RA Macro"
            ).toUpperCase()
          }}</b-th
        >
        <b-th class="th-class" :colspan="2">CRÉDITOS</b-th>
        <b-th
          class="th-class-3"
          :rowspan="2"
          v-if="school && school.show_ra_macro"
          >{{
            $getVisibleNames(
              "teaching.ramacro",
              false,
              "RA Macro"
            ).toUpperCase()
          }}</b-th
        >
        <b-th class="th-class-3" :rowspan="2">{{
          $getVisibleNames(
            "mesh.competence",
            false,
            "Competencia"
          ).toUpperCase()
        }}</b-th>
        <b-tr>
          <b-th class="th-class-2">CANTIDAD</b-th>
          <b-th class="th-class-2">PONDERACIÓN</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <template v-if="competenceList.length > 0">
          <template v-for="(competence, index) in competenceList">
            <template v-if="competence.matters.length > 0">
              <b-tr
                v-for="(profile_matter, index2) in competence.matters"
                :key="'competence:' + index + 'matter:' + index2"
              >
                <b-td
                  class="td-class"
                  :rowspan="competence.matters.length + 1"
                  v-if="index2 == 0"
                >
                  {{ competence.order }}.{{ competence.cycle }}
                </b-td>
                <b-td
                  class="td-class pl-3"
                  :rowspan="competence.matters.length + 1"
                  v-if="index2 == 0"
                >
                  <SentenceContainer
                    :Large="50"
                    :Sentence="competence"
                    :boundedTextContainer="true"
                  ></SentenceContainer>
                </b-td>
                <b-td
                  class="td-class pl-3"
                  :rowspan="competence.matters.length + 1"
                  v-if="index2 == 0"
                >
                  {{ competence.type }}
                </b-td>
                <b-td class="td-class"> {{ profile_matter.name }}</b-td>
                <b-td class="td-class"> {{ profile_matter.plan_level }}</b-td>
                <b-td
                  class="td-class text-left pl-2"
                  v-if="school && school.show_ra_macro"
                >
                  <SentenceContainer
                    :Large="50"
                    :Sentence="profile_matter"
                    :boundedTextContainer="true"
                  ></SentenceContainer
                ></b-td>
                <b-td class="td-class"> {{ profile_matter.credits }}</b-td>
                <b-td class="td-class">
                  {{
                    matterWeighing(profile_matter.credits, competence.matters)
                  }}
                  %</b-td
                >
                <b-td
                  class="td-class"
                  v-if="school && school.show_ra_macro"
                  v-bind:class="
                    profile_matter.achievement == 0
                      ? ''
                      : profile_matter.achievement <= 59
                      ? 'bg-red'
                      : profile_matter.achievement <= 84
                      ? 'bg-yellow'
                      : 'bg-green'
                  "
                >
                  {{ profile_matter.achievement | Round }} %</b-td
                >
                <b-td
                  class="td-class"
                  :rowspan="competence.matters.length + 1"
                  v-if="index2 == 0"
                  v-bind:class="
                    competence.achievement == 0
                      ? ''
                      : competence.achievement <= 59
                      ? 'bg-red'
                      : competence.achievement <= 84
                      ? 'bg-yellow'
                      : 'bg-green'
                  "
                  >{{ competence.achievement | Round }} %</b-td
                >
              </b-tr>
              <b-tr :key="'competence:' + index">
                <b-td class="td-class"> - </b-td>
                <b-td class="td-class" v-if="school && school.show_ra_macro">
                  -
                </b-td>
                <b-td class="td-class"> <strong>Total</strong> </b-td>
                <b-td class="td-class">
                  <strong>{{
                    maxCredits(competence.matters).max_credits
                  }}</strong></b-td
                >
                <b-td class="td-class">
                  <strong
                    >{{ maxCredits(competence.matters).max_weighing }} %</strong
                  >
                </b-td>
                <b-td class="td-class" v-if="school && school.show_ra_macro">
                  -
                </b-td>
              </b-tr>
            </template>
            <template v-else>
              <b-tr :key="'competence:' + index">
                <b-td class="td-class">
                  {{ competence.order }}.{{ competence.cycle }}
                </b-td>
                <b-td class="td-class pl-3">
                  <SentenceContainer
                    :Large="50"
                    :Sentence="competence"
                    :boundedTextContainer="true"
                  ></SentenceContainer>
                </b-td>
                <b-td class="td-class pl-3">
                  {{ competence.type }}
                </b-td>
                <b-td class="td-class"> - </b-td>
                <b-td class="td-class"> - </b-td>
                <b-td class="td-class" v-if="school && school.show_ra_macro">
                  -
                </b-td>
                <b-td class="td-class"> - </b-td>
                <b-td class="td-class"> - </b-td>
                <b-td class="td-class" v-if="school && school.show_ra_macro">
                  -
                </b-td>
                <b-td
                  class="td-class"
                  v-bind:class="
                    competence.achievement == 0
                      ? ''
                      : competence.achievement <= 59
                      ? 'bg-red'
                      : competence.achievement <= 84
                      ? 'bg-yellow'
                      : 'bg-green'
                  "
                  >{{ competence.achievement | Round }} %</b-td
                >
              </b-tr>
            </template>
          </template>
        </template>
        <template v-else>
          <b-tr>
            <b-td colspan="9" class="td-class">No hay datos que mostrar</b-td>
          </b-tr>
        </template>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "AchievementsPerCompetenceTable",
  components: {
    SentenceContainer: () => import("@/components/reusable/SentenceContainer"),
  },
  props: {
    user_id: {
      type: Number,
      required: true,
    },
    egress_profile_id: {
      type: Number,
      required: true,
    },
    checked: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      competence_list: [],
    };
  },
  computed: {
    ...mapGetters({
      profile_cycles: names.PROFILE_CYCLES,
      egress_profiles: names.EGRESS_PROFILES,
      careers: names.CAREERS,
      school: "getInstitution",
    }),
    career() {
      if (!this.egress_profile) return null;
      return this.careers.find((x) => x.id == this.egress_profile.career);
    },
    egress_profile() {
      if (!this.egress_profile_id) return null;
      return this.egress_profiles.find((x) => x.id == this.egress_profile_id);
    },
    competenceList() {
      if (this.competence_list.length > 0) {
        let list = this.competence_list;
        return list.sort(function (a, b) {
          if (a.cycle > b.cycle) return 1;
          if (a.cycle < b.cycle) return -1;
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        });
      } else return this.competence_list;
    },
  },
  methods: {
    maxCredits(matters) {
      let max = { max_credits: 0, max_weighing: 0 };
      matters.forEach((matter) => {
        max.max_credits += matter.credits;
        max.max_weighing += this.matterWeighing(matter.credits, matters);
      });
      return max;
    },
    matterWeighing(credits, matters) {
      let total = 0;
      matters.forEach((matter) => {
        total += matter.credits;
      });
      if (total == 0) return 0;
      else
        return +(Math.round(Number((credits / total) * 100) + "e+2") + "e-2");
    },
    getCycle(cycle_id) {
      if (!cycle_id) return null;
      const cycle = this.profile_cycles.find((x) => x.id == cycle_id);
      if (cycle) return cycle.order;
      else return null;
    },
    fetchAchievementsPerCompetenceTable() {
      if (this.checked == false) {
        this.$restful
          .Get(
            `/teaching/competence_achievement_by_student/?student=${this.user_id}&egress_profile=${this.egress_profile_id}&use_current_sections=True`
          )
          .then((response) => {
            let list = [];
            response.forEach((x, index) => {
              x.cycle = this.getCycle(x.cycle);
              list.push(x);
              if (index + 1 == response.length) this.competence_list = list;
            });
          });
      } else
        this.$restful
          .Get(
            `/teaching/competence_achievement_by_student/?student=${this.user_id}&egress_profile=${this.egress_profile_id}`
          )
          .then((response) => {
            let list = [];
            response.forEach((x, index) => {
              x.cycle = this.getCycle(x.cycle);
              list.push(x);
              if (index + 1 == response.length) this.competence_list = list;
            });
          });
      // this.competence_list = [
      //   {
      //     id: 1,
      //     achievement: 44.5,
      //     verb: "Memorizar",
      //     action: "Memorizar",
      //     content: "aaaaaaaaaaaaa",
      //     condition: "aaaaaaaaaa",
      //     full_sentence: "Memorizar aaaaaaaaaaaaa aaaaaaaaaa",
      //     cycle: null,
      //     order: 1,
      //     matters: [
      //       {
      //         id: 1,
      //         name: "ASIGNATURA 1",
      //         plan_level: "1",
      //         verb: "Enumerar",
      //         action: "Enumerar",
      //         content: "bbbbbbb",
      //         condition: "bbbbbbbbbbb",
      //         full_sentence: "Enumerar bbbbbbb bbbbbbbbbbb",
      //         credits: 1,
      //         achievement: 24,
      //       },
      //       {
      //         id: 2,
      //         name: "ASIGNATURA 2",
      //         plan_level: "1",
      //         verb: "Definir",
      //         action: "Definir",
      //         content: "aaaaaaaaaaaaaaaaaaa",
      //         condition: "aaaaaaaaaaaaaa",
      //         full_sentence: "Definir aaaaaaaaaaaaaaaaaaa aaaaaaaaaaaaaa",
      //         credits: 2,
      //         achievement: 65,
      //       },
      //     ],
      //   },
      //   {
      //     id: 2,
      //     achievement: 100.0,
      //     verb: "Enumerar",
      //     action: "Enumerar",
      //     content: "aaaaaaaaaaaaa",
      //     condition: "aaaaaaaaaa",
      //     full_sentence: "Enumerar aaaaaaaaaaaaa aaaaaaaaaa",
      //     cycle: 1,
      //     order: 2,
      //     matters: [
      //       {
      //         id: 1,
      //         name: "ASIGNATURA 1",
      //         plan_level: "1",
      //         verb: "Definir",
      //         action: "Definir",
      //         content: "bbbbbbb",
      //         condition: "bbbbbbbbbbb",
      //         full_sentence: "Definir bbbbbbb bbbbbbbbbbb",
      //         credits: 1,
      //         achievement: 100,
      //       },
      //       {
      //         id: 2,
      //         name: "ASIGNATURA 3",
      //         plan_level: "1",
      //         verb: "Describir",
      //         action: "Describir",
      //         content: "aaaaaaaaaaaaaaaaaaa",
      //         condition: "aaaaaaaaaaaaaa",
      //         full_sentence: "Describir aaaaaaaaaaaaaaaaaaa aaaaaaaaaaaaaa",
      //         credits: 2,
      //         achievement: 100,
      //       },
      //     ],
      //   },
      // ];
    },
  },
  created() {
    this.fetchAchievementsPerCompetenceTable();
    this.$store
      .dispatch(names.FETCH_EGRESS_PROFILE, this.egress_profile_id)
      .then((response) => {
        this.$store.dispatch(names.FETCH_CAREER, response.career);
      });
  },
};
</script>
<style scoped>
table thead >>> .th-class {
  background-color: var(--primary-color) !important;
  vertical-align: middle !important;
}
table thead >>> .th-class-2 {
  background-color: var(--primary-hover-color) !important;
  vertical-align: middle !important;
}
.th-class-3 {
  background-color: var(--kl-fourth-hover-color) !important;
  color: var(--secondary-font-color) !important;
  vertical-align: middle !important;
}
.td-class {
  vertical-align: middle !important;
}
.bg-red {
  background-color: #f19292 !important;
}
.bg-yellow {
  background-color: #fff1ac !important;
}
.bg-green {
  background-color: #bbeaa6 !important;
}
</style>